// import Inputmask from 'inputmask';
import Flatpickr from 'stimulus-flatpickr'

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  initialize () {
    const firstDayOfWeek = document.head.querySelector('meta[name=first_day_of_week]').content

    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      altInput: true,
      locale: { firstDayOfWeek },
      // dateFormat: 'Y-m-d',
      altFormat: 'M j, Y'
      // allowInput: true
    }

    // Inputmask({ mask: '99/99/9999', placeholder: 'mm/dd/yyyy' }).mask(this.element);
  }

  // all flatpickr hooks are available as callbacks in your Stimulus controller
  // change(selectedDates, dateStr, instance) {
  //   console.log('the callback returns the selected dates', selectedDates);
  //   console.log('but returns it also as a string', dateStr);
  //   console.log('and the flatpickr instance', instance);
  // }
}
