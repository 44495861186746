import { Modal } from 'tailwindcss-stimulus-components'

import { stimulus } from '~/init/stimulus'

export default class ModalAsyncController extends Modal {
  static targets = ['closeIcon']

  close () {
    this.element.remove()
    ModalAsyncController.modalTurboFrame.src = null
    super.close()
  }

  escClose (event) {
    if (event.key === 'Escape') this.close()
  }

  focusOnClose () {
    this.closeIconTarget.classList.add('modal__close-animate')
    setTimeout(() => this.closeIconTarget.classList.remove('modal__close-animate'), 1000)
  }

  static get modalTurboFrame () {
    return document.querySelector('turbo-frame[id=\'async_modal\']')
  }
}

stimulus.register('modal-async', ModalAsyncController)
