import { Alert } from 'tailwindcss-stimulus-components'

export default class FlashController extends Alert {
  connect () {
    super.connect()

    if (this.data.has('autoClose')) {
      setTimeout(() => this.close(), this.data.get('autoClose'))
    }
  }

  show () {
    this.element.setAttribute(
      'style',
      'transition: 0.15s; transform:translate(0, 0);'
    )
  }

  hide () {
    this.element.setAttribute(
      'style',
      'transition: 0.15s; transform:translate(400px, 0);'
    )
  }
}
